.app {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.content {
  flex: 1;
  overflow: hidden;
}